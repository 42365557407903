import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import {useLocation} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { HOST } from '../lib/vars/host';
import { formatHeure, formatDate } from '../lib/func/formatHeure';

export default function Confirmation() {

    const location = useLocation();
    let navigate = useNavigate();

    const debut = new Date(location.state.debut);
    const fin   = new Date(location.state.fin);
    const periode = location.state.periode;

    const [ local,   setLocal   ] = useState();
    const [ loading, setLoading ] = useState(true);
    const [ erreur,  setErreur  ] = useState(null);

    const locaux = [ ...location.state.local ];
    let locauxNom = [];

    window.scrollTo(0,0);

    useEffect( () => {

        async function getLocaux( lx ){

            for( let lo of lx ) {

                const options = {
                    method: 'post',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({
                        id: lo
                    })
                }

                const response = await fetch(`${HOST}/local`, options);
                const r = await response.json();
            
                    
                locauxNom.push( r.data[0].nom );

            }
         
            setLocal( locauxNom );
            setLoading(false);
        }

        if( locauxNom.length === 0 ){
            getLocaux( locaux );
        }

    }, []);



    const Failed = () => {

        return (

            <div className="text-light" style={{maxWidth: "90%", margin: "auto"}}>

                <span className="material-symbols-outlined" style={{color: "red", fontSize: "100px", verticalAlign: "bottom"}}>
                    close
                </span>

                <br/>

                Nous avons rencontré un problème lors de votre réservation. <br/>
                Vous pouvez réessayer ou communiquer avec Cedric McGraw.<br/>
                Merci.
                
            </div>

        )

    }



    const Success = () => {

        const dateDebutHzero = new Date( debut );
        dateDebutHzero.setHours( 0, 0, 0, 0);
        const dateFinHzero = new Date( fin );
        dateFinHzero.setHours( 0, 0, 0, 0 );

        const serie = dateDebutHzero < dateFinHzero;

        const dateDebut = formatDate( debut );
        const dateFin = formatDate( fin );
        const heureDebut = formatHeure( debut );
        const heureFin   = formatHeure( fin );

        return (

            <div className="text-light" style={{maxWidth: "90%", margin: "auto"}}>

                <span className="material-symbols-outlined" style={{color: "#00cc00", fontSize: "100px", verticalAlign: "bottom"}}>
                    event_available
                </span>

                <br/>

                <p style={{fontSize: "30px", fontWeight:"bolder"}}>Réservation réussie</p>

                <div style={{border:"solid 1px white", maxWidth:"500px", margin:"auto", padding:"20px", borderRadius:"10px" }}>

                    { local.map( (l, i, arr) =>{ 

                        let texte = l;

                        if (i + 1 < arr.length) {
                            texte += ", ";
                        }
                        
                        return <span key={texte} className='texte-pri-conf'>{texte}</span>
                    
                    } ) }

                    <p className='texte-sec-conf'>pour le </p>

                    <p className='texte-pri-conf'>{dateDebut}</p> 
                    
                    { serie && 
                        <> <p className='texte-sec-conf'>au</p> <p className='texte-pri-conf'> {dateFin}</p></>
                    }
                    
                    { periode ? 
                        <p className='texte-sec-conf'>pour la</p> :
                        <p className='texte-sec-conf'>de</p> 
                    }
                
                    { periode ?
                        periode === "Diner" ? 
                            <p className='texte-pri-conf'>période du {periode.toLowerCase()}</p> :
                            <p className='texte-pri-conf'>période {periode}</p> :
                        <p className='texte-pri-conf'>{heureDebut} <span className='texte-sec-conf'>à</span> {heureFin}</p>
                    }

                </div>

                <br/>
                <span style={{fontSize:"12px"}}>Voici le code de cette réservation. Ce code est nécessaire pour sa suppression.</span> <br/>
                <span style={{fontSize:"20px", color: "#00cc00"}}>{location.state.succes.motPasse}</span>
                <br></br>
                <button className='btn btn-primary mt-4' onClick={()=>{navigate( '/')}} >Retour au menu</button>
                        
            </div>
        )
    }


    
    return (
            <div className="text-light">
    
                <Header />

                <section>
                   
                    <div className="arr-plan text-center">

                        <div className='container glass'>

                            { loading ? 
                                
                                <Loading /> 

                                :

                                !location.state.succes.failed ?
                            
                                    <Success />
                                    
                                    :
                                    
                                    <Failed />
                                
                            }

                        </div>
                            
                    </div>
                    
                </section>

                <Footer />

            </div>
        )
    
}