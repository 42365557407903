import React from 'react';
import { version } from '../lib/vars/version';

function Footer(  ) {

  return (
    <footer className="page-footer">
            
            <div className="text-muted small mb-0">
              &copy; ODYTEK {new Date().getFullYear()} 
              <span className="small"> - v{version}</span>
            </div>

    </footer>
  )
}

export default Footer;