import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Retour } from '../components/Retour';
import { useNavigate } from "react-router-dom";

export default function ChoixAfficher () {

    useEffect( () => {
        document.getElementById("btn-1").classList.add("btn-animate");
        document.getElementById("btn-2").classList.add("btn-animate");
    })

   
    let navigate = useNavigate();

    const date = () =>{
        navigate( '/reservationsDate', { });
    }

    const locaux = () => {
        navigate( '/reservationsLocaux', { });
    }

    return (
            <div className="text-light">
    
                <Header />

                <section>

                    <div className="arr-plan">
                        
                        <div className='btn-container glass p-5'>

                            <button id="btn-1" className='btn btn-primary btn-block p-3 mb-4' onClick={date}>
                                <span className="material-symbols-outlined mr-2" style={{fontSize: "40px", verticalAlign: "bottom"}}>
                                    calendar_month
                                </span><br></br>
                                par date
                            </button>

                            <button id="btn-2" className='btn btn-primary btn-block p-3 mb-3' onClick={locaux}>
                                <span className="material-symbols-outlined mr-2" style={{fontSize: "40px", verticalAlign: "bottom"}}>
                                    meeting_room
                                </span><br></br>
                                par locaux
                            </button>

                            <Retour />

                        </div>

                    </div>
                    
                </section>

                <Footer />

            </div>
        )
    
}