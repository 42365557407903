import React from "react";

import './App.css';

import { Routes, Route } from "react-router-dom"

import Home       from "./pages/Home";
import Locaux     from "./pages/Locaux";
import ReservationsDate from './pages/ReservationsDate';
import ReservationsLocaux from './pages/ReservationsLocaux';
import ReservationsListe from './pages/ReservationsListe';
import Reserver from './pages/Reserver';
import Confirmation from './pages/Confirmation';
import ChoixAfficher from './pages/ChoixAfficher';

export default function App() {

  return (
        
    <Routes>
      <Route path = "/"       element = {<Home />}   />
      <Route path = "locaux" element = {<Locaux />} />
      <Route path = "reservationsDate" element = {<ReservationsDate />} />
      <Route path = "reservationsLocaux" element = {<ReservationsLocaux />} />
      <Route path = "reserver" element = {<Reserver />} />
      <Route path = "confirmation" element = {<Confirmation />} />
      <Route path = "choix" element = {<ChoixAfficher />} />
      <Route path = "reservationsListe" element = {<ReservationsListe />} />
    </Routes>
        
    )
  
}
