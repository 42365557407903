import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from "react-router-dom";

export default function HomePage () {

    useEffect( () => {
        document.getElementById("btn-1").classList.add("btn-animate");
        document.getElementById("btn-2").classList.add("btn-animate");
    })
   
    let navigate = useNavigate();

    const reserver = () =>{
        navigate( '/reserver', { });
    }

    const reserveDate = () => {
        navigate( '/choix', { });
    }

    return (
            <div> 
    
                <Header />

                <section>

                    <div className="arr-plan">
                        
                        <div className='btn-container p-5 glass'>

                            <button id="btn-1" className='btn btn-primary btn-block p-3 mb-4' onClick={reserver}>
                                <span className="material-symbols-outlined mr-2" style={{fontSize: "40px", verticalAlign: "bottom"}}>
                                    check_box
                                </span><br></br>
                                Faire une réservation
                            </button>

                            <button id="btn-2" className='btn btn-primary btn-block p-3' onClick={reserveDate}>
                                <span className="material-symbols-outlined mr-2" style={{fontSize: "40px", verticalAlign: "bottom"}}>
                                    view_list
                                </span><br></br>
                                Voir les réservations
                            </button>

                        </div>

                    </div>
                    
                </section>

                <Footer />

            </div>
        )
    
}