import React, { useState } from 'react';

export default function Utilisateur ( { update, loadUser } ) {

    const [ user, setUser ] = useState(loadUser);

    const handleUserChange = (e) => {
        setUser( e.target.value );
    }

    const onSelect = (e) => {

        e.target.select();

    }

    const handleBlur = (e) => {

        let user = e.target.value.split("@");
        user = user[0].toLowerCase();
   
        update(user);
        
    }

    return (

        <div className='container mt-3'>
            <p className='text-light m-2'>Nom d'utilisateur</p>
            <input 
                id        = "user"
                name      = "user"
                type      = "text"
                value     = {user}
                onClick   = {onSelect}
                onChange  = {handleUserChange}
                onBlur    = {handleBlur}
                className = "form-control ml-3"
                style     = {{ maxWidth: "300px"}}
                placeholder = "nom d'utilisateur"
                aria-label  = "Inscris le nom d'utilisateur"
                aria-describedby = "basic-addon1"
            />
        </div>
    )
}