import React, {useEffect, useState} from 'react';
import CheckBox from "./CheckBox";
import DatePicker from 'react-date-picker';
import horaireDate from '../lib/vars/horaire';


export default function DateChoisi ( { update } ) {

    const setFin = () => {

        let now = new Date();
        now.setHours( now.getHours() + 1 );

        return now;

    }

    const [ dateValue, setDateValue ] = useState( new Date() );
    const [ dateValueDebut, setDateValueDebut ] = useState( new Date() );
    const [ dateValueFin, setDateValueFin ] = useState( setFin() );
    const [ stateHeureDebut, setHeureDebut ] = useState( dateValue.getHours() );
    const [ stateMinutesDebut, setMinutesDebut ] = useState( dateValue.getMinutes() );
    const [ stateHeureFin, setHeureFin ] = useState( dateValue.getHours() + 1 );
    const [ stateMinutesFin, setMinutesFin ] = useState( dateValue.getMinutes()  );
    const [ horaire, setHoraire ] = useState( horaireDate(new Date()));
    const [ erreur, setErreur ] = useState( false );
    const [ erreurDate, setErreurDate ] = useState( false );
    const [ erreurDateFin, setErreurDateFin ] = useState( false );
    const [ showHeurePers, setShowHeurePers ] = useState( false );
    const [ showDateFin, setShowDateFin ] = useState( false );
    const [ dateFinChange, setDateFinChange ] = useState( false );
    const [ finDeSemaine, setFinDeSemaine ] = useState( false );
    const [ periode, setPeriode ] = useState( false );


    useEffect( () => {

       update( dateValueDebut, dateValueFin, erreur, erreurDate, finDeSemaine, periode  );

    }, []);
   
    useEffect( () => {
        
       update( dateValueDebut, dateValueFin, [erreur, erreurDate, erreurDateFin].some( e => e), finDeSemaine, periode );

    }, [erreur, erreurDate, erreurDateFin, finDeSemaine, periode ]);



    const onChangeDate = ( date ) => {

        let debut = new Date( dateValueDebut );
        const now = new Date();

        now.setHours(0,0,0,0);

        if( date < now ){
            date = now;
            setErreurDate( "La date ne doit pas être plus tôt qu'aujourd'hui." ); 
        } else {
            setErreurDate( false ); 
        }

        debut.setMonth(     date.getMonth()     );
        debut.setDate(      date.getDate()      );
        debut.setFullYear(  date.getFullYear()  );
        
        let fin = new Date( dateValueFin );
     
        if( !dateFinChange ){

            fin.setMonth( date.getMonth() );
            fin.setDate( date.getDate() );
            fin.setFullYear( date.getFullYear() );

            setDateValueFin( fin );

        }
       
        setDateValue( date );
        setHoraire( horaireDate(date) );
        setDateValueDebut( debut );

        update( debut, fin, erreur, finDeSemaine, periode );
    
    }

    const onChangeDateFin = ( date ) => {

        setDateFinChange(true);
        
        let debut = new Date( dateValueDebut );
        let fin = new Date( dateValueFin );

        fin.setMonth( date.getMonth() );
        fin.setDate( date.getDate() );
        fin.setFullYear( date.getFullYear() );

        console.log(fin)
        
        if( fin <= debut ){
            setErreurDateFin("La date de fin doit être plus tard que la date de début.")
        }else{
            setErreurDateFin(false);
        }
        setDateValueFin( fin );

        update( debut, fin, erreur, finDeSemaine, periode );
    
    }

    const onSelect = (e) => {

        e.target.select();

    }

    const onChangeHeure = (e) => {  

        setPeriode( false );

        let heure, minute = 0;

        if( e.target.id === "heure" ){

            if( e.target.value > 23 ){ 
                heure = 23;
            } else if( e.target.value < 0  ){ 
                heure = 0; 
            } else if( isNaN(e.target.value) ){
                heure = 0;
            } else { 
                heure = e.target.value;
            }

            minute = dateValue.getMinutes();

        } else {

            heure = dateValue.getHours();

            if( e.target.value > 59 ){ minute = 59 }
            else if( e.target.value < 0  ){ minute = 0 }
            else { minute = e.target.value }

        }

        dateValue.setHours( heure, minute );

        let newHeure = dateValue;
        let heureFin = stateHeureFin;
        let minutesFin = stateMinutesFin;

        // if( !this.finChanger ){
        //     heureFin = parseInt( heure ) + 1;
        //     minutesFin = parseInt(minute ) + 10;
        //     if( minutesFin > 59 ) {
        //         minutesFin -= 60;
        //         heureFin += 1;
        //     }
        // }
;
        setDateValue( newHeure);
        setHeureDebut( heure );
        setMinutesDebut( minute );
        setHeureFin( heureFin );
        setMinutesFin( minutesFin );

        validateHeure();
  
        update( dateValue, dateValueFin, erreur, finDeSemaine, periode );
        
    }


    const onChangeHeureFin = (e) => { 

        
        setPeriode( false );

        let heure, minute = 0;
        

        // if( this.state.erreur ){

        //     this.setState({
        //         erreur: null
        //     })

        // }

        if( e.target.id === "heureFin" ){

            if( e.target.value > 23 ){ 
                heure = 23;
            } else if( e.target.value < 0  ){ 
                heure = 0; 
            } else if( isNaN(e.target.value) ){
                heure = 0;
            } else { 
                heure = e.target.value;
            }

            minute = stateMinutesFin;

        } else {

            heure = stateHeureFin;

            if( e.target.value > 59 ){ minute = 59 }
            else if( e.target.value < 0  ){ minute = 0 }
            else { minute = e.target.value }

        }

        
        dateValueFin.setHours( heure, minute)
        
        setHeureFin(heure);
        setMinutesFin(minute);
        
        validateHeure();
        
        update( dateValue, dateValueFin, erreur, finDeSemaine, periode )

    }

    const validateHeure = () => {

        setErreur(false);

        for( let i = 0 ; i<6; i++){
            if(document.getElementById(i)){
                document.getElementById(i).style.backgroundColor = "#007bff";
            }
        }

        const elementHeureFin = document.getElementById("heureFin");
        const elementMinuteFin = document.getElementById("minuteFin");

        const heureDebut  = Number(document.getElementById("heure").value);
        const minuteDebut = Number(document.getElementById("minute").value);
        const heureFin    = Number(elementHeureFin.value);
        const minuteFin   = Number(elementMinuteFin.value);

        if( ( heureFin * 60 + minuteFin - 30 ) < ( heureDebut * 60 + minuteDebut ) ){
            elementHeureFin.style.color = "red";
            elementMinuteFin.style.color = "red";
            setErreur("L'heure de fin doit être au moins 30 minutes après l'heure de début.")
        } else {
            elementHeureFin.style.color = "black";
            elementMinuteFin.style.color = "black";
        }

    }

    const handleHeurePers = (e) => {

        const div = document.getElementById("heurePers");

        if( showHeurePers ){
            div.classList.remove("show");
            div.classList.add("hide");
        } else {
            div.classList.remove("hide");
            div.classList.add("show");
        }

        setShowHeurePers(!showHeurePers);

    }


    const handleDateFinDiv = (e) => {

        const div = document.getElementById("dateFin");

        if( showDateFin ){
            div.classList.remove("show");
            div.classList.remove("showOverFlow");
            div.classList.add("hide");
        } else {
            div.classList.remove("hide");
            div.classList.add("show");
            setTimeout( () => {
                div.classList.add("showOverFlow");
            }, 600 );
            
            setDateFinChange(false);

            let fin = new Date( dateValueDebut );
            fin.setHours( fin.getHours() + 1 );
            setDateValueFin( fin );
        }

        setShowDateFin(!showDateFin);

    }


    const handleFS = ( valeur, checked ) => {
        setFinDeSemaine(checked);
    }

    const handleHoraire = (e) => {

        setErreur(false);
        
        const heure = horaire.heures[e.target.id];
        
        let debut = new Date( dateValueDebut );
        let fin   = new Date( dateValueFin );

        debut.setHours( heure.d.h, heure.d.m );
        fin.setHours( heure.f.h, heure.f.m );
        
        setHeureDebut( heure.d.h );
        setHeureFin( heure.f.h );
        setMinutesDebut( heure.d.m);
        setMinutesFin( heure.f.m);
        setPeriode( e.target.name );

        setDateValueDebut( debut );
        setDateValueFin( fin );

        update( debut, fin, erreur, finDeSemaine, periode);

    }

    return (

        <div className="container mb-4 p-4">

            <div className='row' style={{marginLeft:"0px"}}>

                {/**************************** DATE ************************** */}

                <label className="text-light col-sml mb-1">

                    Date

                    <br />

                    <DatePicker  
                        format = "dd-MM-y"
                        onChange={onChangeDate}
                        value={dateValueDebut   }
                        className="ml-2 mt-2 datepicker"
                        style={{ backgroundColor: "#8a8a8a" }}
                        clearIcon = {null}
                    />
                </label>

            </div>

              {/**************************** ERREUR ************************** */}
              { erreurDate && erreurDate.length > 0 &&
                
                <div className='row ml-2' style={{flexWrap:"nowrap"}}>
                    
                    <span className='alert alert-danger'>{erreurDate}</span>

                </div>
            }

            <div className='ml-2 small mt-2' style={{cursor:"pointer", color: "#fff"}} onClick={handleDateFinDiv}>
                
                
                {/* <span className="material-symbols-outlined" style={{fontSize: "30px", verticalAlign: "middle"}}>
                    date_range
                </span> */}
                    { showDateFin ?
                        <>
                            Enlever la date de fin
                            <span className="material-symbols-outlined" style={{fontSize: "25px", verticalAlign: "middle"}}>
                                expand_less
                            </span>
                        </>
                        :
                        <>
                            Ajouter une date de fin
                            <span className="material-symbols-outlined" style={{fontSize: "25px", verticalAlign: "middle"}}>
                                expand_more
                            </span>
                        </>
                    }
                    
            </div>

          

            <div id="dateFin" className=' row hide ml-2 blocAnime' style={{marginLeft:"0px"}}>

                {/**************************** DATE ************************** */}

                <label className="text-light col-sml m-3">

                    Date de fin

                    <br />

                    <DatePicker  
                        format = "dd-MM-y"
                        onChange={onChangeDateFin}
                        value={dateValueFin}
                        className="ml-2 mt-2 datepicker"
                        style={{ backgroundColor: "#8a8a8a" }}
                        clearIcon = {null}
                    />
                    
                    <div className='ml-2 mt-2 mb-2'>
                        <CheckBox 
                            update={handleFS} 
                            permission={ true }
                            disponible={ true }
                            valeurProp="finDeSemaine"
                            messageProp="Inclure les fins de semaine" 
                            enabledProp={ true }
                            key="finDeSemaine"
                            />
                    </div>

                </label>

            

            </div>

                  {/**************************** ERREUR ************************** */}
                  { erreurDateFin && erreurDateFin.length > 0 &&
                
                <div className='row ml-2' style={{flexWrap:"nowrap"}}>
                    
                    <span className='alert alert-danger'>{erreurDateFin}</span>

                </div>
            }

            <div className='mt-3'>Période de la journée</div>
            <div className='row pl-4 pt-2 ' style={{flexWrap:"nowrap"}}>
                
                <div className='col-l'>
                { horaire.horaire && horaire.horaire.length > 0 && horaire.horaire.map( ( p, index) => {

                    return <button className={`btn btn-primary mr-2 mt-2 btn-horaire ${periode === p && "btn-select" }`} key={index} name={p} id={index} onClick={handleHoraire}>{p}</button>

                })}
                </div>
            </div>
            
            <div className='ml-4 small mt-2' style={{cursor:"pointer"}} onClick={handleHeurePers}>
                <span className="material-symbols-outlined" style={{fontSize: "30px", verticalAlign: "middle"}}>
                    nest_clock_farsight_analog
                </span>
                    { showHeurePers ?
                        <span className="material-symbols-outlined" style={{fontSize: "20px", verticalAlign: "middle"}}>
                            expand_less
                        </span>
                        :
                        <span className="material-symbols-outlined" style={{fontSize: "20px", verticalAlign: "middle"}}>
                            expand_more
                        </span>
                    }
            </div>
            
            
                <div id="heurePers" className='row hide ml-2 blocAnime'>

                    {/**************************** DEBUT ************************** */}
                    <div className='col-sml m-3'>
                        <label className="text-light">
                            Début
                        </label>

                        <div className="ml-2" style={{ width:"130px", flexWrap:"nowrap", backgroundColor:"white", borderRadius: "5px", paddingLeft:"5px", padding:"2px" }}>

                            <input type="number" id="heure" name="heure"
                            
                                onClick={onSelect}
                                onChange={onChangeHeure}
                                value={stateHeureDebut}
                                className=""
                                size="2"
                                maxLength="2"
                                style={{textAlign: "center", width: "50px", border:"none"}}

                            />

                            <span className="" style={{color:"black", maxWidth:"30px"}}>h</span>

                            
                                <input type="number" id="minute" name="minute"
                                    style={{display: "", maxWidth:"50px", textAlign:"center", border:"none"}}
                                    onClick={onSelect}
                                    onChange={onChangeHeure}
                                    value={stateMinutesDebut}
                                    className=""
                                    size="2"
                                    maxLength="2"
                            
                                />

                        </div>
                    </div>




                    {/**************************** FIN ************************** */}
                    <div className='col-sml m-3'>
                        <label className="text-light">

                                Fin
                                
                        </label>

                        <div className="ml-1" style={{ width:"130px", flexWrap:"nowrap", backgroundColor:"white", borderRadius: "5px", paddingLeft:"5px", padding:"2px" }}>

                            <input type="number" id="heureFin" name="heureFin"

                                onClick={onSelect}
                                onChange={onChangeHeureFin}
                                value={stateHeureFin}
                                className=""
                                size="2"
                                maxLength="2"
                                style={{textAlign: "center", width: "50px", border:"none"}}

                            />

                            <span className="" style={{color:"black", maxWidth:"30px"}}>h</span>


                            <input type="number" id="minuteFin" name="minuteFin"
                                style={{display: "", maxWidth:"50px", textAlign:"center", border:"none"}}
                                onClick={onSelect}
                                onChange={onChangeHeureFin}
                                value={stateMinutesFin}
                                className=""
                                size="2"
                                maxLength="2"

                            />

                        </div>
                        
                    </div>
                </div>
            


           {/**************************** ERREUR ************************** */}
           { erreur && erreur.length > 0 &&
            
                <div className='row ml-2' style={{flexWrap:"nowrap"}}>
                    
                    <span className='alert alert-danger'>{erreur}</span>

                </div>
            }

        </div>

     
    )
}