import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import { Supprimer } from '../components/Supprimer';
// import { Modifier } from '../components/Modifier';
import { HOST } from '../lib/vars/host';
import { useLocation } from "react-router-dom";
import { Retour } from '../components/Retour';
import { formatDate } from '../lib/func/formatHeure';
import horaireDate from '../lib/vars/horaire';

export default function ReservationsListe() {

    const location = useLocation();
    const date = location.state.date;
    
    const [ reservations, setReservations ] = useState([]);
    const [ locaux, setLocaux ] = useState([]);
    const [ modal, setModal ] = useState(false);
    const [ loading, setLoading ] = useState(true);

    useEffect( () => {

        getReservations();

    }, [])

    const getReservations = () => {

        setModal(false);
        setLoading(true);
        setReservations([]);

        const options = {
            method: 'post',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
              data: {date: date}
            })
        }

        fetch(`${HOST}/getReservationsDate`, options)
        .then(response => response.json() )
        .then(data =>{
            
            let reservations = data.data;

            reservations.sort( (a, b) => {

                let aHeight = new Date(a.fin) - new Date(a.debut);
                let bHeight = new Date(b.fin) - new Date(b.debut);

                return ( aHeight < bHeight ? 1 : aHeight > bHeight ? -1 : 0 );
           
            } )

            setReservations(reservations);
        })
        .catch( err => {console.log( err )});

        fetch(`${HOST}/locaux`, options)
        .then(response => response.json() )
        .then(data =>{
        
            setLocaux(data.data);

            setLoading(false);

        })
        .catch( err => {console.log( err )});

    }

    const handleClick = (e, id) =>{

        let modal = {};

        let reservation = reservations.find( r => r.id === id );
        const local = locaux.find( e => Number(e.id) === Number(reservation.local) );

        let debut = new Date(reservation.debut);
        let fin   = new Date(reservation.fin);      


        
        let debutMinute = debut.getMinutes();

        if( debutMinute < 10 ){
            debutMinute = "0" + debut.getMinutes();
        } 



        let finMinute = fin.getMinutes();

        if( finMinute < 10 ){
            finMinute = "0" + fin.getMinutes();
        } 
            
        

        let heure = `${ debut.getHours()} h ${ debutMinute }`;
        let heureFin = `${ fin.getHours()} h ${ finMinute }`;

        let nomComp = reservation?reservation.qui.split("."):"";
        let prenom = nomComp[0][0].toUpperCase() + nomComp[0].slice(1);
        let nom = nomComp[1][0].toUpperCase() + nomComp[1].slice(1);


        // ********* Calcule la position du modal selon l'écran ****************
        let posX, posY;

        if( e.clientX + 250 > window.innerWidth ){
            posX = window.innerWidth - 250;
        } else 
        if( e.clientX - 150 < 0 ){
            posX = 0;
        } else
        {
            posX = e.clientX - 150;
        }
        
        if( e.clientY + 250 > window.innerHeight ){
            posY = window.innerHeight - 250;
        } else 
        if( e.clientY - 60 < 0 ){
            posY = 0;
        }else {
            posY = e.clientY -60;
        }
        //***************************************************************

        modal = {
            posX: posX,
            posY: posY,
            debut: heure,
            fin: heureFin,
            reservation: reservation,
            local: local.nom,
            serie: reservation.reserveID,
            qui: `${prenom} ${nom}`,
            id: id
        }


        setTimeout( ()=>{
            document.getElementById("modal").classList.remove( "modal-hide");
            document.getElementById("modal").classList.add( "modal-show");
        }, 50);

        window.addEventListener( "click", handleModelClickClose);

        setModal(modal);

    }

    const handleModelClickClose = (e) => {

        if( e.target.id === "modal" ){

            closeModal();

        }

    }

    const afficherDate = () => {

        return ( <h2 className='mb-4'>le { formatDate( date ) }</h2>)

    }

    const closeModal = () => {

        window.removeEventListener( "click", handleModelClickClose);

        document.getElementById("modal").classList.remove( "modal-show");
        document.getElementById("modal").classList.add( "modal-hide");

        setTimeout( ()=>{setModal(false);}, 200)
        
    }

    const afficheHoraire = () => {

        let horaire = horaireDate( date );
        horaire = horaire.horaire;

        return (
            <>
                <div className='gridHoraire' style={{
                    top: "60px",
                    height: "60px"
                }}>Période {horaire[0]}</div>

                <div className='gridHoraire' style={{
                    top: "125px",
                    height: "60px"
                }}>Période {horaire[1]}</div>

                <div className='gridHoraire' style={{
                    top: "195px",
                    height: "60px"
                }}>Période {horaire[2]}</div>

                <div className='gridHoraire' style={{
                    top: "255px",
                    height: "60px"
                }}>Diner</div>

                <div className='gridHoraire' style={{
                    top: "315px",
                    height: "60px"
                }}>Période {horaire[4]}</div>
                
                <div className='gridHoraire' style={{
                    top: "380px",
                    height: "30px"
                }}>Période {horaire[5]}</div>
                
                <div className='gridHoraire' style={{
                    top: "420px",
                    height: "60px"
                }}>Période {horaire[6]}</div>
            </>

        )
    }

    return (
    <div>
    
        <Header />

        <section>
           
            <div className="arr-plan">

                <div className='container text-light glass'>

                    <Retour />

                    <h2 className='text-center'>{afficherDate()}</h2>

                    <div className='col-sml mr-2' style={{float: "left"}}>
                        <div className='horaireGrid'> 8 h 00</div>
                        <div className='horaireGrid'> 9 h 00</div>
                        <div className='horaireGrid'>10 h 00</div>
                        <div className='horaireGrid'>11 h 00</div>
                        <div className='horaireGrid'>12 h 00</div>
                        <div className='horaireGrid'>13 h 00</div>
                        <div className='horaireGrid'>14 h 00</div>
                        <div className='horaireGrid'>15 h 00</div>
                        <div className='horaireGrid'>16 h 00</div>
                        <div className='horaireGrid'>17 h 00</div>
                        <div className='horaireGrid'>18 h 00</div>
                    </div>

                    

                    <div className='dayGrid'>
                        <div className='gridView'>

                            { loading && <Loading />}

                            { afficheHoraire() }

                            {reservations && reservations.length > 0 && reservations.map( (r, i) => {

                                let local = locaux.find( e => Number(e.id) === Number(r.local) );
                   
                                if( !local || !local.nom ) return <></>;
                            
                                let debut = new Date(r.debut);
                                let fin = new Date(r.fin);

                                let start = ( ( debut.getHours() - 8) * 120 ) + ( debut.getMinutes() * 2 );
                                let end =  ( ( fin.getHours() - 8) * 120 ) + ( fin.getMinutes() * 2 );
                            
                                return  <div className="card text-center" onClick={(e)=>{handleClick( e, r.id) } } style={
                                            {
                                                gridRow: `${start / 2 + 1} / ${end / 2 + 1}`,
                                                cursor: "pointer",
                                                zIndex: 3
                                            }}>
                                            <div className='card-body'>
                                                { local && local.nom }
                                            </div>
                                        </div>
                            })}


                        </div>

                        <div className='mb-3' />
                    </div>
                    <Retour />
                </div>
               
               {modal && modal.qui &&
                
                    <div id="modal" className="modal modal-hide" tabIndex="-1" role="dialog" style={{ color:"darkgray" }}>
                        
                        <div className="modal-dialog" role="document" style={{
                                top: modal.posY, 
                                left: modal.posX
                                }}>

                            <div className="modal-content">

                                <div className="modal-header">
                                    <span className="material-symbols-outlined ml-2" onClick={closeModal} style={{float: "right", cursor: "pointer", color: "white", fontSize: "22px", verticalAlign: "bottom"}}>
                                        close
                                    </span>
                                    <h5 className="modal-title">{modal.local}</h5>
                              
                                    
                                </div>

                                <div className="modal-body">
                                    <p className='mb-0'>{modal.qui}</p>
                                    <p className='mt-0'>{modal.debut} à {modal.fin}</p>
                                </div>
                                <div className='text-center pb-3'>
                                    <Supprimer id={modal.id} update={getReservations} serie={false}/>
                                    { modal.serie &&
                                        <Supprimer id={modal.id} update={getReservations} serie={modal.serie}/>
                                    }
                                    {/* <Modifier  id={modal.id} update={getReservations}/> */}
                                </div>
                            </div>

                        </div>

                    </div>
                }

                 

            </div>
            
        </section>

        <div style={{clear: "both"}}>
            <Footer />
        </div>     

    </div>
    )

}