import React, { Component, useState, useEffect, memo } from 'react';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import startOfWeek from 'date-fns/startOfWeek';
import startOfToday from 'date-fns/startOfToday';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import addDays from 'date-fns/addDays';
import endOfMonth from 'date-fns/endOfMonth';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import format from 'date-fns/format';
import { frCA } from 'date-fns/locale';
import {HOST} from '../lib/vars/host'
import { isWeekend } from 'date-fns';
import { isAfter } from 'date-fns/esm';
import Loading from './Loading';
import randomColor from '../lib/func/randomColor';


export const CalendrierLocaux = memo(  ( { reservationsProp, updateModal } ) => {

  const [ currentMonth, setCurrentMonth] = useState( new Date() );
  const [ selectedDate, setSelectedDate] = useState( new Date() );
  const [ reservations, setReservations] = useState();
  const [ erreur, setErreur ] = useState( null );     
  const [ loading, setLoading ] = useState( false );
          
            
  useEffect( () => {

    // let colorsByID = [];

    // reservationsProp.forEach( r => {

    //   const trouver = colorsByID.find( c => { return c.id && c.id === r.reserveID; } );
    //   let couleur = "";

    //   if( trouver ){

    //     r.couleur = trouver.couleur;

    //   } else {

    //     couleur = randomColor();
    //     r.couleur = couleur;

    //     if( r.reserveID.length > 0 ){

    //       colorsByID.push( { id: r.reserveID, couleur: couleur } );

    //     }
        
    //   }

    // });

    setReservations( reservationsProp );

  }, [ reservationsProp ] )

  const setModalReservation = ( r, e ) => {

    r.clientX = e.clientX;
    r.clientY = e.clientY;
    
    updateModal( r );
  
  }
    
  const renderHeader = () => {

        const dateFormat = "MMMM y";
    
        return (
          <div className="headerCalendar rowCalendar flex-middle ">

            {/* <div className="colCalendar col-startCalendar"> */}
           
            <span className="material-symbols-outlined cal-title" style={ { cursor: 'pointer', padding: "5px", paddingLeft: "10px" } } onClick={prevMonth}>
    
              arrow_back_ios

            </span>
             
            <div className="colCalendar col-centerCalendar cal-title">
              <span>{ format( currentMonth, dateFormat, { locale: frCA} ) }</span>
            </div>

            <span className="material-symbols-outlined cal-title" style={ { cursor: 'pointer', padding: "5px", paddingLeft: "10px" } } onClick={nextMonth}>
      
              arrow_forward_ios

            </span>

          </div>
        );
    }
    
    const renderDays = () => {
        const dateFormat = "E";
        const days = [];
    
        let startDate = startOfWeek(currentMonth);
    
        for (let i = 0; i < 7; i++) {
          days.push(
            <div className="colCalendar col-centerCalendar" key={i}>
              {format(addDays(startDate, i), dateFormat, {locale: frCA})}
            </div>
          );
        }
    
        return <div className="days rowCalendar">{days}</div>;
      }
    
    const renderCells = () => {
        
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
    
        const dateFormat = "d";
        const rows = [];
    
        let days = [];
        let day = startDate;
        
        let formattedDate = "";
    
        while (day <= endDate) {

          for (let i = 0; i < 7; i++) {

            formattedDate = format(day, dateFormat);

            const cloneDay = day;
            let found;
            
            if( reservations ){

              found = reservations.filter( (e) => { 

                let debut = new Date(e.debut);
                debut.setHours(0, 0, 0, 0);

                let fin = new Date(e.fin);
                fin.setHours(0, 0, 0, 0);

                return debut <= cloneDay && fin >= cloneDay;

              } );

            }
           
            days.push(
              
              <div
                className={`colCalendar cell disabled ${isSameDay(day, selectedDate) ? "selected" : ""} 
                ${ isWeekend( day ) || !isSameMonth(day, monthStart) 
                      ? "weekend" : "" }`
                }
                key={day}
                style={{cursor: "default"}}
              >
                <div className="number disabled">{formattedDate}</div>
                
                { found && found.length > 0 && found.map( r => {
                        
                  return <div onClick={(e)=>{setModalReservation(r,e)}} style={{cursor:"pointer", backgroundColor: r.couleur }}
                          className='eventsCalLocal'
                            >{r.nomComplet}<br/>{r.heureDebut}</div>
                  })

                }
                
                <span className="bg">{formattedDate}</span>
              </div>
            );
            day = addDays(day, 1);
          }
          rows.push(
            <div className="rowCalendar" key={day}>
              {days}
            </div>
          );
          days = [];
        }
        return <div className="body">{rows}</div>;
      }
    
      // const onDateClick = (day) => {
      //   update( day );
      // };
    
      const nextMonth = () => {
        
          setCurrentMonth( addMonths(currentMonth, 1) );
       
      };
    
  const prevMonth = () => {
        
    setCurrentMonth( subMonths(currentMonth, 1) );
       
  };

  return (

    <div>

      { loading ? 
          <Loading /> :
      
        erreur ?
          <div className='alert alert-danger m-5'>{erreur}</div> :

        <div className="calendar m-3">
          {renderHeader()}
          {renderDays()}
          {renderCells()}
        </div>
      }
     
    </div>


  );
      
})