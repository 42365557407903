import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { CalendrierLocaux } from '../components/CalendrierLocaux'
import Loading from '../components/Loading';
import { HOST } from '../lib/vars/host';
import { Retour } from '../components/Retour';
import { Modal } from '../components/Modal';
import { formatDate, formatHeure} from '../lib/func/formatHeure';

export default function ReservationsLocaux() {

    const [ locaux,  setLocaux  ] = useState([]);
    const [ local, setLocal ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ reservations, setReservations ] = useState([]);
    const [ reservationsLocal, setReservationsLocal ] = useState([]);
    const [ modal, setModal ] = useState(false);

    useEffect( () => {

        getReservations();

    }, []);


    useEffect( () => {

        handleSelect();

    }, [reservations]);


    const getReservations = () => {

        setModal(false);

        const options = {
            method: 'post',
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }

        fetch(`${HOST}/getReservations`, options)
        .then(response => response.json() )
        .then(data =>{
            
            let reservations = data.data;

            if( reservations && reservations.length > 0 ){

                reservations.forEach( r => {

                    let debut = new Date(r.debut);
                    let fin = new Date(r.fin);      
                                            
                    let heure = `${ formatHeure( debut ) }`;
                    let heureFin = `${ formatHeure( fin ) }`;
            
                    let nomComp = r?r.qui.split("."):"";
                    let prenom = nomComp[0][0].toUpperCase() + nomComp[0].slice(1);
                    let nom = nomComp[1][0].toUpperCase() + nomComp[1].slice(1);

                    r.nomComplet = `${prenom} ${nom}`;
                    r.prenom = prenom;
                    r.dateDebut = `du ${formatDate(debut)}`;
                    r.dateFin = `au ${formatDate(fin)}`;
                    r.heureDebut = heure;
                    r.heureFin = heureFin;
                });
            } else {
                reservations = [];
            }

            setReservations(reservations);
         
        });

        fetch(`${HOST}/locaux`, options)
        .then(response => response.json() )
        .then(data =>{

                 
            let locaux = data.data;
            locaux.sort( (a, b) => a.nom.localeCompare(b.nom) )
            setLocaux(locaux);

            setLoading(false);

        })
        .catch( err => {
            console.log(err);
        });

    }

    const handleSelect = (e) => {

        let lo;

        if( e && e.target ){
            lo = e.target.value;
            setLocal( e.target.value );
        } else if( e && !e.target ){
            lo = e;
        } else if( local ) {
            lo = local;
        } else {
            return;
        }

        let res = reservations.filter( r => r.local === lo);

        res.sort( (a, b) => {

            let debut = new Date(a.debut);
            let fin = new Date(b.debut);

            return ( debut - fin );
       
        } )

        setReservationsLocal( res );

    }

    const handleModal = ( reservation ) => {

        const r = JSON.parse( JSON.stringify( reservation ) );

        setModal( r );

    }


    const closeModal = () => {

        setModal( false );
    }


    return (
    <div className="text-light">
    
        <Header />

        <section>
           
            <div className="arr-plan">
                <div className='container glass'>
                    { loading ? <Loading />
                    :
                    <>
                    <Retour />
                    <select id="local" className="form-control dropLocaux m-auto" onChange={handleSelect} defaultValue={'default'}>
                        <option key="de" value="default" disabled>Choisi un local</option>
                        { locaux && locaux.length > 0 && locaux.map( (l) => {
                            return <option key={l.id} value={l.id}>{l.nom}</option>
                        })}

                    </select>
                    
                    { (!reservationsLocal || reservationsLocal.length === 0) && <div className='text-center'>Aucune réservation</div>}

                    <div>

                        { reservationsLocal && reservationsLocal.length > 0 &&
                            <CalendrierLocaux reservationsProp={reservationsLocal} updateModal={ handleModal } />
                        }

                        {/* <h2 className='text-center mt-5' style={{borderBottom: "1px solid white"}}>Les prochains 30 jours</h2>

                        { reservationsLocal && reservationsLocal.length > 0 && reservationsLocal.map( r => {

                            const now = new Date();
                            let later = new Date();
                            later = later.setDate(later.getDate() + 30 ) ;

                            if( new Date(r.dateFin) < now || new Date(r.dateFin) > later ){
                                return false;
                            }

                            return  <div key={r.id} className="reservation text-center">
                      
                                            <div>{r.nomComplet}</div>
                                            <div className='small'>{r.dateDebut} à {r.heureDebut} </div>
                                            <div className='small '>{r.dateFin} à {r.heureFin}</div>
                            
                                    </div>
                        })} */}

                    </div>
                    </>}
                </div>

             
            </div>
            { modal && 
                <Modal reservation={ modal } locaux={ locaux } closeModal={closeModal} updateRes={getReservations} />
            }
        </section>

        <Footer />

    </div>
    )

}