import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from "react-router-dom";
import Calendrier from '../components/Calendrier';
import { Retour } from '../components/Retour';

export default function ReservationsDate() {

    let navigate = useNavigate();

    const changeDate = (date) => {
        navigate( "/reservationsListe", { state: { date: date } } );
    }

    return (
    <div>
    
        <Header />

        <section>
           
            <div className="arr-plan">

                <div className='container glass'>

                    <Retour />

                    <Calendrier update={changeDate} />
                    
                    <Retour />

                </div>
            </div>
            
        </section>

        <Footer />

    </div>
    )

}