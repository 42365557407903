import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loading from '../components/Loading'
import { HOST } from '../lib/vars/host';
import DateChoisi from '../components/DateChoisi';
import Utilisateur from '../components/Utilisateur';
import { useNavigate } from "react-router-dom";

export default function Reserver () {

    let userInfo = localStorage.getItem("user");
    let loadUser = "";

    if( userInfo ){ 
        loadUser = JSON.parse( userInfo );
        loadUser = loadUser.id;
    }

    const [ dateDebut, setDateDebut ] = useState();
    const [ dateFin, setDateFin ] = useState();
    const [ finDeSemaine, setFinDeSemaine ] = useState();
    const [ periode, setPeriode ] = useState(false);
    const [ user, setUser ] = useState(loadUser);
    const [ erreur, setErreur ] = useState();
    const [ erreurDate, setErreurDate ] = useState(false);
    const [ erreurConnection, setErreurConnection ] = useState(null);
    // const [ refuser, setRefuser ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    let navigate = useNavigate();
 
    const handleDateChange = ( dateDebut, dateFin, erreur, finDeSemaine, periode ) => {
  
        setErreurDate(erreur);
        setDateDebut(dateDebut);
        setDateFin(dateFin);
        setFinDeSemaine(finDeSemaine);
        setPeriode(periode);

    }

    const handleUser = ( user ) => {
        if( erreur ) setErreur(null);
        setUser(user);
    }

    // const reservDate = () => {
    //     navigate( '/reservations', { });
    // }

    const NavMenu = () => {
        return  <div className='d-flex flex-row-reverse'>
                    {/* <button className='btn btn-primary mt-4' onClick={()=>navigate(-1)} >
                            <span className="material-symbols-outlined mr-2" style={{fontSize: "22px", verticalAlign: "bottom"}}>
                                west
                            </span>
                            
                        </button> */}
                
                    { loading ? 
                        <Loading />
                    
                    :
                    <button className='btn btn-primary mt-4' onClick={suivant} >
                        
                        Suivant
                        <span className="material-symbols-outlined ml-2" style={{fontSize: "22px", verticalAlign:"bottom" }}>
                            east
                        </span>
                    </button>}
                </div>
    }

    const suivant =  (e) => {

        setLoading(true);

        if( !user || user === "" ){
            setErreur("Le nom d'utilisateur n'est pas valide.");
            setLoading( false );
            return;
        }

        if( erreurDate ){
            setLoading(false);
            // setRefuser(true);
            e.target.classList.add( "bounce" );
            setTimeout( (e) => { e.target.classList.remove( "bounce" ) }, 1200, e )
            return;
        }
        
        const options = {
            method: 'post',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                data: { 
                    user: user
                }
            })
        }

        fetch(`${HOST}/checkUser`, options)
        .then(response => response.json() )
        .then(data =>{
            setLoading(false);
            if( data.failed ){
                window.scrollTo(0,0);
                setErreur("Le nom d'utilisateur n'est pas valide.");

                return;
            }
            let userInfo =  JSON.stringify(data.data[0]);

            localStorage.setItem( "user", JSON.stringify(data.data[0]) );
      
            navigate( '/locaux', { state: {debut: dateDebut, fin: dateFin, user: userInfo, fs: finDeSemaine, periode: periode } });

        })
        .catch( err => {
            setErreurConnection("La connection au serveur a échoué.\nCommuniquer avec Cedric McGraw.");
        });

    }

    if( erreurConnection ){

        return (
            <div className='m-5'>
                            
                <div className='alert alert-danger'>{erreurConnection}</div>

            </div>
            )
    }

    return (
            <div className="text-light">
    
                <Header />

                <section>

                   
                    <div className="arr-plan">

                        <div className='container pt-4 pb-4 glass' style={{boxShadow: "0px 0px 20px 10px rgba(0, 0, 0, .2)", maxWidth:"650px"}}>
                            
                            
                            <h2 className='mb-4 text-center'>Réserver un local</h2>
                            
                            {/* <NavMenu /> */}
                           
                            <Utilisateur update={ handleUser } loadUser={ user } />
                            
                            { erreur &&
                                <div className='ml-4 mt-3' >
                                    
                                    <span className='alert alert-danger'>{erreur}</span>

                                </div>
                            }

                            <DateChoisi update={ handleDateChange } />
              

                            <NavMenu />
                            
                        </div>   

                    </div>
                    
                </section>

                <Footer />

            </div>
        )
    
}